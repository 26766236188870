import { SpoolSdk, getSepoliaConfig, getMainnetConfig } from '@spool.fi/spool-v2-sdk'
import { getEthersProvider, getEthersSigner } from 'wallet'
import { constants } from 'helpers'

const graphApiKey = process.env.NEXT_PUBLIC_GRAPH_API_KEY

if (!graphApiKey) {
  throw new Error('GRAPH_API_KEY not provided')
}

export const yelayNetworkName = constants.isDev ? 'sepolia' : 'mainnet'
export const yelayStartBlock = constants.isDev ? 5875804 : 21215096
export const yelayVaults = [
  {
    address: constants.isDev
      ? '0x09d17590d67bec9129f329f950aee874ec70d599'
      : '0xdf038e151e4e1b45d8d599847d00d697b8c26ef0',
    credits: 0.38,
    depositTimeLockDays: 90,
  },
  {
    address: constants.isDev
      ? '0x92b13936d5d03386953086a228bc8d96690cbf95'
      : '0x40b42ff3a97349af978a500989a8b4368f1f7620',
    credits: 0.33,
    depositTimeLockDays: 0,
  },
]

export type PoolBaseDate = (typeof yelayVaults)[number]

export type Token = {
  decimals: number
  address: Address
  name: string
  symbol: string
}

// export const tokens: Token[] = [constants.tokensBySymbol.USDC, constants.tokensBySymbol.DAI]
export const tokens: Token[] = [constants.tokensBySymbol.USDC]

const getConfig = () => {
  if (constants.isDev) {
    // `https://gateway-testnet-arbitrum.network.thegraph.com/api/2bbe50ba02d7cce62ec0a1480750a5ae/subgraphs/id/J5s1Q5ECEuvcyr8hfCVJxdebmwiQTGWbNGXu8GLfnSBj`,
    return getSepoliaConfig('https://subgraph.satsuma-prod.com/49eb322da234/solidant/spool-v2-sepolia/api')
  }

  return getMainnetConfig(
    `https://gateway-arbitrum.network.thegraph.com/api/${graphApiKey}/subgraphs/id/41A5vZJ2fypeqtD8o5rD1HB7GjVkvP55NR3EeZDXkk2s`,
  )
}

export const yelayConfig = getConfig()

export const getYelayViewSDK = () => {
  const provider = getEthersProvider({ chainId: constants.appChainId })

  return new SpoolSdk(yelayConfig, provider)
}

export const getYelaySignerSDK = async () => {
  const provider = await getEthersSigner({ chainId: constants.appChainId })

  return new SpoolSdk(yelayConfig, provider!)
}
