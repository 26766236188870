import { constants } from 'helpers'
import { Icon } from 'components/ui'

export default function CoinImage({ className, symbol }: { className?: string; symbol: string }) {
  const iconName = constants.tokensBySymbol[symbol]?.icon

  return (
    <div className={className}>
      {iconName ? (
        <Icon className="size-full" name={iconName} />
      ) : (
        <img
          className="size-full object-contain object-center"
          src={`/images/coins/${symbol.toLowerCase()}.png`}
          alt={symbol}
        />
      )}
    </div>
  )
}
